import React from "react"
import { Route } from "react-router-dom"
import LoginView from "./views/auth/LoginView"
import { Settings } from "./Settings"

const routes = [
  <Route path="/login" component={LoginView} />,
  <Route path="/settings" component={Settings} />
]

export default routes
