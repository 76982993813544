import React, { useEffect } from "react"
import { createTheme } from "@material-ui/core/styles"
import { defaultTheme } from "react-admin"
import merge from "lodash/merge"
import { Admin, Resource } from "react-admin"
import { useLocation, useHistory } from "react-router-dom"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import { ApolloProvider } from "@apollo/client"

import { dataProvider, authProvider } from "./AdminSetup"
import apolloClient from "./apolloClient"
import "./App.css"
// import Dashboard from "./Dashboard"
import { GradeChangeList, GradeChangeShow } from "./resources/GradeChange"
import { PredictionShow } from "./resources/Predictions"
import { GradeList, GradeShow } from "./resources/Grades"
import Layout from "./Layout"
import keycloak from "./keycloak"
import customRoutes from "./routes"
import LoginView from "./views/auth/LoginView"
import History from "@material-ui/icons/History"
import TrendingUp from "@material-ui/icons/TrendingUp"

// Really cool:
// https://marmelab.com/blog/2020/09/11/react-admin-tutorials-build-your-own-theme.html#unleash-the-power-of-overrides
const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#f5f5f5",
        contrastText: "#006d32",
      },
      secondary: {
        main: "#006d32",
      },
    },
    props: {
      MuiLink: {
        color: "secondary",
      },
      RaLink: {
        link: {
          color: "secondary",
        },
      },
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: "#006d32",
        },
      },
      RaLink: {
        link: {
          color: "#006d32",
        },
      },
      RaReferenceField: {
        link: {
          color: "#006d32",
        },
      },
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['"Lato"', "sans-serif"].join(","),
    },
  })
)

const CatchAll = props => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      history.replace(window.location.hash.substr(1))
    }, 1000)
  }, [location.pathname, history])

  return <div></div>
}

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initConfig={{
          onLoad: "check-sso",
        }}
        onTokens={tokens => {
          if (process.env.NODE_ENV === "development") {
            // console.log("onKeycloakTokens", tokens)
            // console.log("idToken", tokens.idToken)
            console.log("accessToken", `Bearer ${tokens.token}`)
            // console.log("refreshToken", tokens.refreshToken)
          }
        }}
        onEvent={evt => {
          if (evt === "onAuthSuccess") {
          }
          console.log('keycloakEvent', evt)
        }}
      >
        <Admin
          theme={theme}
          dataProvider={dataProvider}
          authProvider={authProvider}
          // dashboard={Dashboard}
          customRoutes={customRoutes}
          layout={Layout}
          catchAll={CatchAll}
          loginPage={LoginView}
          title="Nucor Grade Changes"
        >
          <Resource
            name="predictions"
            list={PredictionShow}
            icon={TrendingUp}
          />
          <Resource
            name="grade_changes"
            options={{ label: "Grade Change History" }}
            list={GradeChangeList}
            show={GradeChangeShow}
            icon={History}
          />
          <Resource name="grades" list={GradeList} show={GradeShow} />
          <Resource name="elements" />
        </Admin>
      </ReactKeycloakProvider>
    </ApolloProvider>
  )
}

export default App
