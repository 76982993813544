import React from "react"
import { Layout, AppBar } from "react-admin"
import Typography from "@material-ui/core/Typography"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"

import Menu from "./Menu"

import nucorLogo from "./images/nucor.svg"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: 10,
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "105px",
    marginLeft: -35,
  },
  dbLogo: {},
})

const useLayoutStyles = makeStyles(theme => ({
  contentWithSidebar: {
    paddingTop: theme.spacing(3),
  },
}))

const CustomAppBar = props => {
  const classes = useStyles()

  return (
    <AppBar {...props} color="primary">
      <Toolbar>
        <img src={nucorLogo} alt="logo" className={classes.logo} />
      </Toolbar>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
      ></Typography>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  )
}

const CustomLayout = props => {
  const classes = useLayoutStyles()
  return <Layout {...props} classes={classes} appBar={CustomAppBar} menu={Menu} />
}

export default CustomLayout
