import * as React from 'react';
import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import lodash from 'lodash';
import {
  Button,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Title, useAuthenticated, useNotify } from 'react-admin';
import { useQuery, useMutation } from "@apollo/react-hooks"

const MixModelID = process.env.REACT_APP_MIX_MODEL_PREDICTION_TYPE_ID

const MIX_MODEL_SETTINGS_QUERY = gql`
  query GetPredictionType($typeId: bigint!) {
    prediction_types_by_pk(id: $typeId) {
      id
      name
      settings
    }
  }
`

const SAVE_MIX_MODEL_SETTINGS = gql`
  mutation SavePredictionTypeSettings($typeId: bigint!, $settings: jsonb) {
    update_prediction_types_by_pk(pk_columns: {id: $typeId}, _set: {settings: $settings}) {
      id
      name
      settings
    }
  }
`

const CLEAR_CACHED_PREDICTIONS = gql`
  mutation DeleteMixModelPredictions($typeId: bigint) {
    delete_grade_change_predictions(where: { prediction_type_id: { _eq: $typeId } }) {
      affected_rows
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const Settings = () => {
  useAuthenticated()
  const notify = useNotify()
  const [settings, setSettings] = useState({
    castRateConstant: 10.7,
    tundishGainRate: 0,
    finalTundishWeight: 50000,
    heatSize: 125,
    tonsPerInchForCuts: 0.00685,
    // poundsPerInchForCuts: 13.7,
    defaultStrandSpeed: 67.0,
    defaultTundishStartWeight: 20000,
  })
  const { data, loading, error } = useQuery(MIX_MODEL_SETTINGS_QUERY, {
    variables: { typeId: MixModelID },
  })

  const [saveSettings] = useMutation(SAVE_MIX_MODEL_SETTINGS, {
    onCompleted: () => {
      notify('Settings Saved!', 'info')
    },
    onError: ({graphQLErrors, networkError}) => {
      if (networkError) {
        notify(networkError, 'error')
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          notify(message, 'error')
        })
      }
    }
  })

  const [clearCachedPredictions] = useMutation(CLEAR_CACHED_PREDICTIONS, {
    onCompleted: (data) => {
      const affectedRows = data.delete_grade_change_predictions.affected_rows
      notify(`Cleared out ${affectedRows} cached predictions`, 'info')
    },
    onError: ({graphQLErrors, networkError}) => {
      if (networkError) {
        notify(networkError, 'error')
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          notify(message, 'error')
        })
      }
    }
  })

  const classes = useStyles()

  useEffect(() => {
    const modelSettings = data?.prediction_types_by_pk?.settings || {}
    if (!lodash.isEmpty(modelSettings)) {
      setSettings(modelSettings)
    }
  }, [data])

  if (loading) {
    return <CircularProgress color="secondary" />
  }

  if (error) {
    error.graphQLErrors?.forEach(({ message }) => {
      notify(message, 'error')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    saveSettings({
      variables: { typeId: MixModelID, settings },
    })
    clearCachedPredictions({
      variables: { typeId: MixModelID }
    })
  }

  const onSettingChange = (settingName) => (event) => {
    event.preventDefault()
    setSettings({
      ...settings,
      [settingName]: event.target.value
    })
  }

  const SettingField = ({label, setting, onChange}) => {
    return (
    <TextField
      key={setting}
      label={label}
      value={settings[setting]}
      onChange={onSettingChange(setting)}
      type="number"
      variant="filled"
      fullWidth
      className={classes.textField}
    />
    )
  }

  return (
    <Card>
        <Title title="Mix Model Settings" />
        <CardContent>
          <div className={classes.root}>
            <form onSubmit={handleSubmit}>
              {/* Necessary to invoke this way to keep focus */}
              {SettingField({label: "Cast Rate Constant", setting: 'castRateConstant' })}
              {SettingField({label: "Tundish Gain Rate", setting: 'tundishGainRate' })}
              {SettingField({label: "Final Tundish Weight", setting: 'finalTundishWeight' })}
              {SettingField({label: "Heat Size", setting: 'heatSize' })}
              {SettingField({label: "Tons Per Inch Cut", setting: 'tonsPerInchForCuts' })}
              {/*SettingField({label: "Pounds Per Inch Cut", setting: 'poundsPerInchForCuts' })*/}
              {SettingField({label: "Default Strand Speed", setting: 'defaultStrandSpeed' })}
              {SettingField({label: "Default Tundish Start Weight", setting: 'defaultTundishStartWeight' })}
              <Button type="submit" variant="contained" color="secondary">
                Save
              </Button>
            </form>
          </div>
        </CardContent>
    </Card>
  );
};